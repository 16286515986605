// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-tips-for-better-photos-js": () => import("./../../../src/pages/5-tips-for-better-photos.js" /* webpackChunkName: "component---src-pages-5-tips-for-better-photos-js" */),
  "component---src-pages-documentary-js": () => import("./../../../src/pages/documentary.js" /* webpackChunkName: "component---src-pages-documentary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-personal-branding-js": () => import("./../../../src/pages/personal-branding.js" /* webpackChunkName: "component---src-pages-personal-branding-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-preview-galleries-js": () => import("./../../../src/pages/preview/galleries.js" /* webpackChunkName: "component---src-pages-preview-galleries-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */)
}

